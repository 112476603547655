<template>
    <PriceOffer :requster_type="requster_type" :price_offers="price_offers" v-if="this.$store.state.showOfferPrice == true" />
    <button @click="this.$store.state.showOfferPrice = true" class="btn-third">{{ $t('Show_Price') }}</button>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
    data() {
        return {
            showOfferPrice:false
        };
    },
    components: {
        PriceOffer: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/PriceOffer.vue')),
    },
    props:{
        price_offers:Array,
        requster_type:String
    }
}
</script>
